export const documentsData = Object.freeze([
    {
        icon: require('@/assets/img/documents/contract.svg'),
        title: 'Online English Exam',
        link: '/cp/documents/exam',
        bubbleAttentionGetter: 'getterNeedUpdateExam',
        bubbleAttentionMessage: 'Please pass the exam'
    },
    {
        icon: require('@/assets/img/documents/idea.svg'),
        title: 'Referencing & Plagiarism Manuals',
        link: '/cp/documents/manual',
        bubbleAttentionGetter: null,
        bubbleAttentionMessage: ''
    },
    {
        icon: require('@/assets/img/documents/question.svg'),
        title: 'FAQ',
        link: '/cp/documents/faq',
        bubbleAttentionGetter: null,
        bubbleAttentionMessage: ''
    },
    {
        icon: require('@/assets/img/svg/document.svg'),
        title: 'Freelance Writer Agreement',
        link: '/cp/documents/writer-agreement',
        bubbleAttentionGetter: null,
        bubbleAttentionMessage: ''
    },
    {
        icon: require('@/assets/img/svg/privacy.svg'),
        title: 'Privacy Policy',
        link: '/cp/documents/privacy',
        bubbleAttentionGetter: null,
        bubbleAttentionMessage: ''
    },
    {
        icon: require('@/assets/img/svg/terms.svg'),
        title: 'Terms Of Services',
        link: '/cp/documents/terms',
        bubbleAttentionGetter: null,
        bubbleAttentionMessage: ''
    }
])
