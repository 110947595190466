<template>
    <li
        class="list"
        @click="redirectToThePage"
    >
        <div class="list__subscribe">
            <img :src="item.icon">
            <p class="list__title">
                {{ item.title }}
                <bubble-attention
                    v-if="isShowBubbleAttention"
                    :message="item.bubbleAttentionMessage"
                />
            </p>
        </div>
        <img
            class="list__arrow"
            src="@/assets/img/documents/arrow.svg"
            alt="arrow"
        >
    </li>
</template>

<script>
import BubbleAttention from '@/components/BubbleAttention.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'DocumentListItem',
    components: {
        BubbleAttention
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('writer', [
            'getterNeedUpdateExam'
        ]),
        isShowBubbleAttention() {
            return this[this.item?.bubbleAttentionGetter] ?? false
        }
    },
    methods: {
        redirectToThePage() {
            this.$router.push(this.item.link)
        }
    }
}
</script>

<style lang="scss" scoped>
.list {
    display: flex;
    margin: 30px auto;
    max-width: 1200px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #e7ecf4;
    padding: 25px;
    transition: 0.5s;
    justify-content: space-between;
    cursor: pointer;
    &:hover{
        box-shadow: 0 5px 30px rgb(0 0 0 / 20%);
        color: #1161E0;
    }
    &__subscribe{
        display: flex;
        img {
            width: 40px;
            height: 52px;
        }
    }
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        padding: 11px 30px;
    }
}
</style>
