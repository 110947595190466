<template>
    <main-layout>
        <template #content>
            <header-content
                :header-info="headerInfo"
            />
            <ul class="documents-list">
                <document-list-item
                    v-for="(item,index) in $options.documentsData"
                    :key="index"
                    :item="item"
                />
            </ul>
        </template>
        <footer />
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import DocumentListItem from '@/components/pages/documents/DocumentListItem.vue';
import { documentsData } from '@/helpers/documents/documentsData'
import { documentsBreadcrumbsSteps } from '@/helpers/documents/breadcrumbsStepsList'
import HeaderContent from '../../../components/HeaderContent';

export default {
    documentsData,
    documentsBreadcrumbsSteps,
    HeaderContent,
    components: {
        HeaderContent,
        MainLayout,
        DocumentListItem
    },
    metaInfo: {
        title: 'Documents'
    },
    data() {
        return {
            headerInfo: {
                title: 'Documents',
                className: 'header-content__documents',
                steps: this.$options.documentsBreadcrumbsSteps
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.documents-list {
    margin: 0 auto;
    max-width: 1200px;
}

@media (max-width: 1200px){
    .documents-list {
        margin: 0 20px;
    }
}

</style>
